import { defineComponent, ref, reactive, onMounted } from 'vue'
import './index.less'

export default defineComponent({
    props: ['show', 'data','initVal', 'column', 'selected'],
    emits: ['closePopup','callBack'],
    setup (props: any, { emit }) {


        onMounted(() => {
            _setSencod()
            _handleData()
        })

        const showPopup = ref(props.show)
        const list = reactive({data: props.data})
        const sencod = reactive({ data:  []})

        /**
         * 处理数据
         */
        const _handleData = () => {
            if (JSON.stringify(props.selected) != '{}' && props.column === 2) {
                list.data.forEach((item: any, index: number) => {
                    item['checked'] = false
                    item.children.forEach((ite: any, idx: number) => ite['checked'] = false)
                })

                list.data.forEach((item: any) => {

                    if (item.id === props.selected.parentId) {
                        item['checked'] = true
                        sencod.data = item.children
                    }

                    item.children.forEach((ite: any) => {
                        if (ite.id === props.selected.id) {
                            ite['checked'] = true
                        }
                    })
                })


            } 
        }

        //设置第二列
        const _setSencod = () => {
            if (props.column === 2) {
                sencod.data = list.data[0].children
            }
        }

        const onClose = () => {
            emit('closePopup', false)
        }


        /**
         * 选中二级菜单.
         */
        const onSelectOption = () => {
            list.data.forEach((item: any, index: number) => {
                item['checked'] = false
                item.children.forEach((ite: any, idx: number) => ite['checked'] = false)
            })
           
        }

        const onSelect = (obj: any) => {
           // 有两列,则点击第一列的某一项，显示对应列的子集 
           if (props.column === 2) {

              list.data.forEach((item: any, index: number) => {
                item['checked'] = false
                item.children.forEach((ite: any, idx: number) => {
                    ite['checked'] = false
                })
              })
              obj['checked'] = true
              obj.children[0]['checked'] = true
              sencod.data = obj.children
           } else {
              list.data.forEach((item: any) => item['checked'] = false)
              obj['checked'] = true
              emit('callBack', obj)
              onClose()
           }
        }

        const onSelectSencod = (obj: any) => {
            emit('callBack', obj)
            onClose()
        }
       
        return () => (
            <van-popup 
                show={showPopup.value} 
                get-container="#app" 
                closeable
                position="bottom"
                beforeClose={ ()=> onClose() }
                style={{ height: '60%', width: '100%' }}
                >
                <h2 class="popup-header">请选择</h2>    
                <div class="list-container-box">
                    <div class="list-box">
                        {
                            list.data.map((item:any) => { // 
                                return (
                                    <div 
                                        class={`item-box ${ item.checked  ? 'active': '' }`} 
                                        key={item.id} 
                                        onClick={() => onSelect(item)}
                                        > 
                                        { item.name }
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        props.column !== 2 ? '' : (
                            <div class="list-box">
                                {
                                    sencod.data.map((item:any) => { // 
                                        return (
                                            <div 
                                                class={`item-box ${ item.checked ? 'active': '' }`} 
                                                key={item.id} 
                                                onClick={() => onSelectSencod(item)}
                                                > 
                                                { item.name }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ) 
                    }
                </div>
            </van-popup>
        )
    }
})