import { 
    defineComponent, 
    onMounted, 
    reactive, 
    ref,
    getCurrentInstance, 
    ComponentInternalInstance 
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { 
    industryList, 
    province, 
    workExpList, 
    educationList,
    jobDetail,
    listlikeName,
    circlesParent
 } from '@/api/index'
import { SET_HIGHLIT, SET_PUBLISH1, SET_PUBLISH2 } from '@/utils/constant'

import TuiPopup from '@/components/select/index'

import './index.less'

export default defineComponent({
    name: 'Publish',
    setup () {

        onMounted(() => {
            _industryList()
            _province()
            _workExpList()
            _educationList()
            _initLocal()
            const { id } = route.query
            if (id) {
                _jobDetail(id)
                show.value = false
            } else {
                _monreOpen()
            }
        })

        const router = useRouter()
        const route = useRoute()
        

        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const state: any = reactive({
            showHy: false,//显示职位列表
            cascaderShow: false,
            fieldValue: '',
            cascaderValue: '',
            citys: [], //城市
            citysCode: '',
            workShow: false, //是否打开工作经历
            eduShow: false, //是否打开学历
            form: { } ,//表单
            showopenJob: false, //开放职位，快速复制
            isCopy: false, //是否复制职位
        })
        const show = ref(true) // 是否显示已有复制的职位

        //自定义的省市区
        const fieldNames = {
            text: 'name',
            value: 'id',
            children: 'children',
        };

        //快速复制职位操作
        const ksjob = {
            data: [], // 数据
            selected: {}, //选中的值
            open () {
                state.showopenJob = true 
            },
            close () {
                state.showopenJob = false 
            }
        }

        const  _monreOpen = async(limit = 50, start = 0, name = '') => {
            const res: any = await listlikeName({ limit, start, data: name })
            const data = res.data.map((item: any) => ({ id: item.id, name: item.name, checked: false}))
            ksjob.data = data
        }

        const _initForm = (data: any) => {
            state.form = { ...data }
            initCircles(data.districtId)
            state.citysCode = data.circlesId
            state.form.negotiable = [data.negotiable]
            
        }

        const initCircles = async (id: number) => {
            const res: any = await circlesParent({data: id})
            const mapData = state.citys
                mapData.map((item: any) => {
                    item.children.map((ite: any) => {
                        ite.children.map((it: any) => {
                            if (it.id === id) {
                                it['children'] = res.data
                            }
                        })
                    })
                })
                state.citys = mapData
        }

        const _jobDetail = async(id: any) => {
            const res: any = await jobDetail({data: id})
            _initForm(res.data)
        }

        const _initLocal = () => {
            let res: any = localStorage.getItem(SET_PUBLISH1)
            if (res) {
                res = JSON.parse(res)
                state.form = { ...res }
                state.citysCode = res.districtId
            }
        }

       
        const onNextPage = () => {
            if (!state.form.name) return proxy.$toast('职位名称不能为空！');
            if(state.form.name.length > 20) return proxy.$toast('请输入，不超过20个字符');
            if(!state.form.functionId && !state.form.function)return proxy.$toast('请选择职位类别!');
            if(!state.form.provinceId && !state.form.cityId 
                && !state.form.districtId && !state.form.address)return proxy.$toast('请选择职或输入工作地点!');
            if(state.form.address === undefined || state.form.address === 'undefined') {
                return proxy.$toast('请选择职或输入工作地点!');
            } 
            if (!state.form.salaryMin)return proxy.$toast('请输入最低月薪!');
            if(!state.form.salaryMax)return proxy.$toast('请输入最高月薪!');   
            if (Number(state.form.salaryMin) >= Number(state.form.salaryMax))return proxy.$toast('最低月薪输入有误!');      
           
            const { id } = route.query
            localStorage.setItem(SET_PUBLISH1, JSON.stringify(state.form))
            router.push({path: '/publish2', query: { id: id, isCopy: state.isCopy }})
        }

        // 取消
        const onCancel = () => {
            localStorage.removeItem(SET_HIGHLIT)
            localStorage.removeItem(SET_PUBLISH1)
            localStorage.removeItem(SET_PUBLISH2)
           // router.back()
            router.push({path: '/job'})
        }
        
        //职位类别操作
        const hy = {
            data: [], // 数据
            selected: {}, //选中的值
            open () {
                state.showHy = true 
            },
            close () {
                state.showHy = false 
            }
        }

        //工作经验操作
        const work = {
            data: [],
            selected: {}, //选中的值
            open () {
                state.workShow = true
            },
            close () {
                state.workShow = false
            }
        }

        const edu = {
            data: [],
            selected: {}, //选中的值
            open () {
                state.eduShow = true
            },
            close () {
                state.eduShow = false
            }
        }

        // 学历
        const _educationList = async () => {
            const res: any = await educationList()
            res.data.forEach((item: any, index: number) => {
                index === 0 ? item['checked'] = true : item['checked'] = false
                if (item.name === '不限') {
                    onEduCallBack(item) //默认选中
                }
            })
            edu.data = res.data
        }

        //工作经历
        const _workExpList = async () => {
            const res: any = await workExpList()
            res.data.forEach((item: any, index: number) => {
                index === 0 ? item['checked'] = true : item['checked'] = false
                if (item.name === '不限') {
                    onCallBack(item) //默认选中
                }
            })
            work.data = res.data
        }

        //省市区
        const _province = async () => {
            const res: any = await province()
            state.citys = res.data
        }

        //行业树
        const _industryList = async () => {
            const res: any = await industryList()
            res.data.forEach((item: any, index: number) => {
                index === 0 ? item['checked'] = true : item['checked'] = false
                item.children.forEach((ite: any, idx: number) => {
                    idx === 0 ? ite['checked'] = true : ite['checked'] = false
                })
            })
            hy.data = res.data
        }

        const onCallBackHY = (val: any) => {
            hy.selected = val
            state.form.function = val.name
            state.form.functionId = val.id 
        }

        const onCallBackOpenJob = async (val: any) => {
            const res: any = await jobDetail({data: val.id})
            delete res.data.id
            _initForm(res.data)
            state.isCopy = true
        }

        // 工作
        const onCallBack = (val: any) => {
            work.selected = val
            state.form.workExperienceName = val.name
            state.form.workExperienceId = val.id
            state.form.workExperienceMax = val.workExperienceMax
            state.form.workExperienceMin = val.workExperienceMin
        }

        //学历返回函数
        const onEduCallBack = (val: any) => {
            edu.selected = val
            state.form.educationId = val.id 
            state.form.education = val.name 
        }

        //关闭省市区弹框
        const onCloseCitys = () => {
            state.cascaderShow = false
        }

        const onCloseCityChange = (obj: any) => {
            const arry = obj.selectedOptions
            if (arry.length === 4) {
                state.form.provinceId = arry[0].id
                state.form.province = arry[0].name
                state.form.cityId = arry[1].id
                state.form.city = arry[1].name
                state.form.districtId = arry[2].id
                state.form.district = arry[2].name
                state.form.circlesId = arry[3].id
                state.form.circles = arry[3].name
            }
        }

        const onFinishParent = async (obj: any) => {
            
            const selectedOptions = obj.selectedOptions
            if (selectedOptions.length === 4) {
                return
            }
            const targetOption = selectedOptions[selectedOptions.length - 1];
            const res: any = await circlesParent({data: targetOption.id})
            state.citys
            setTimeout(() => {
                targetOption.children = res.data;
                console.log(targetOption)
              }, 100);
        }

        return () => (
            <div class="publish-page">
                <div class="header-box flex-box justify-content-between">
                    <div>发布职位（1/2）</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="container-box">
                
                {
                    !show.value ? null : (
                        <div class="already-selected-item" onClick= { ksjob.open }>
                            <div>从已有职位快速复制</div>
                            <div><van-icon name="arrow-down" /></div>
                        </div>
                    ) 
                }
                
                <ul class="list-item">
                    <li class="item">
                        <div class="header mt57">职位基本信息<span class="subtitle">(发布后不可修改)</span></div>
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>职位名称</div>
                            <div class="block-input">
                                <input class="input" readonly={!show.value ? true : false} v-model={ state.form.name } placeholder="不超过20个字符" />
                            </div>
                        </div>
                        {
                            show.value ? (
                                <div class="sub-item mt21" onClick={ hy.open }>
                                    <div class="title"><label>*</label>职位类别</div>
                                    <div class="block-input flex-box justify-content-between align-items-center gutter">
                                        <div>{ !state.form.function ? '请选择' : state.form.function }</div>
                                        <div><van-icon name="arrow-down" /></div>
                                    </div>
                                </div>
                            ) : (
                                <div class="sub-item mt21">
                                    <div class="title"><label>*</label>职位类别</div>
                                    <div class="block-input flex-box justify-content-between align-items-center gutter">
                                        <div>{ !state.form.function ? '请选择' : state.form.function }</div>
                                        <div><van-icon name="arrow-down" /></div>
                                    </div>
                                </div>
                            )
                        }
                        
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>工作地点</div>
                            <div class="flex-box justify-content-between align-items-center gutter">
                                
                                {
                                    show.value ? (
                                        <div onClick={ () => state.cascaderShow = true } class="flex-box justify-content-between align-items-center gutter w390 block-input">
                                            <div class="citys-first-box">
                                                {
                                                    (!state.form.province && !state.form.city && !state.form.district) ? '请选择省份和城市' : 
                                                    state.form.province + '/' + state.form.city  + '/' + state.form.district + '/' + state.form.circles
                                                }
                                            </div>
                                            <div><van-icon name="arrow-down" /></div>
                                        </div>
                                    ) : (
                                        <div class="flex-box justify-content-between align-items-center gutter w390 block-input">
                                            <div class="citys-first-box">
                                                {
                                                    (!state.form.province && !state.form.city && !state.form.district) ? '请选择省份和城市' : 
                                                    state.form.province + '/' + state.form.city  + '/' + state.form.district
                                                }
                                            </div>
                                            <div><van-icon name="arrow-down" /></div>
                                        </div>
                                    )
                                }
                                
                                
                                <div class="block-input wl11">
                                    <input class="input-item address-box" readonly={!show.value ? true : false} v-model={ state.form.address } placeholder="请输入详细地址，不超过20个字符" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item">
                            <div class="header mt57">职位要求</div>
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>经验要求</div>
                            <div onClick={ () => work.open() } class="block-input flex-box justify-content-between align-items-center gutter">
                                <div>
                                    { !state.form.workExperienceName ? '请选择' : state.form.workExperienceName }
                                </div>
                                <div><van-icon name="arrow-down" /></div>
                            </div>
                        </div>
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>学历要求</div>
                            <div onClick={ () =>  state.eduShow = true } class="block-input flex-box justify-content-between align-items-center gutter">
                                <div>
                                    { !state.form.education ? '请选择' : state.form.education }
                                </div>
                                <div><van-icon name="arrow-down" /></div>
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <div class="header mt57">薪资待遇</div>
                        <div class="sub-item mt21">
                            <div class="title"><label>*</label>月薪范围</div>
                            <div class="flex-box justify-content-between align-items-center gutter">
                                <div class="flex-box justify-content-between align-items-center gutter w529">
                                    <input 
                                        class="input-item w196 block-input" 
                                        v-model={ state.form.salaryMin } 
                                        placeholder="最低月薪" 
                                        type="number"
                                         /> 
                                    <span class='label-mark'> 至</span>
                                    <input 
                                        class="input-item w196 block-input" 
                                        v-model={ state.form.salaryMax } 
                                        type="number"
                                        placeholder="最高月薪" />
                                        <span class='label-mark'> /月</span>
                                </div>
                                {/* <div class="radio-item">
                                    <van-checkbox-group v-model={ state.form.negotiable } direction="horizontal">
                                        <van-checkbox name={true}>显示面议</van-checkbox>
                                    </van-checkbox-group>
                                </div> */}
                            </div>
                        </div>
                        <div class="sub-item mt21">
                            <div class="title">提成比列</div>
                            <div class="block-input">
                                <input class="input" v-model={ state.form.commission } placeholder="比如15%-30%" />
                            </div>
                        </div>
                    </li>
                </ul>
                </div>
                <div class="footer-item">
                    <button class="cancel-btn" onClick={onCancel}>取消</button>
                    <button class="next-btn" onClick={onNextPage}>下一步{state.showHy}</button>
                </div>

                {
                    !state.showopenJob? '' : 
                     (
                        <TuiPopup
                        show={ state.showopenJob} 
                        data={ ksjob.data } 
                        selected={ ksjob.selected } 
                        column= {1}
                        { ...{  
                            onClosePopup: ksjob.close,
                            onCallBack: onCallBackOpenJob 
                        } }
                        /> 
                     )
                }

                {
                    !state.showHy ? '' : 
                     (
                        <TuiPopup
                        show={ state.showHy} 
                        data={ hy.data }
                        selected={ hy.selected } 
                        column= {2}
                        { ...{  
                            onClosePopup: hy.close,
                            onCallBack: onCallBackHY 
                        } }
                        /> 
                     )
                }


                {
                    !state.workShow? '' : 
                     (
                        <TuiPopup
                        show={ state.workShow} 
                        data={ work.data } 
                        selected={ work.selected } 
                        column= {1}
                        { ...{  
                            onClosePopup: work.close,
                            onCallBack: onCallBack 
                        } }
                        /> 
                     )
                }

                {
                    !state.eduShow? '' : 
                     (
                        <TuiPopup
                        show={ state.eduShow} 
                        data={ edu.data } 
                        column= {1}
                        { ...{  
                            onClosePopup: edu.close,
                            onCallBack: onEduCallBack 
                        } }
                        /> 
                     )
                }

                <van-popup 
                    show={ state.cascaderShow } 
                    get-container="#app" 
                   
                    position="bottom"
                    >
                    <van-cascader 
                        v-model={ state.citysCode }
                        title="请选择所在地区" 
                        options={ state.citys }
                        field-names={ fieldNames }
                        onClose= { onCloseCitys } 
                        onChange= { onCloseCityChange }
                        onFinish ={ onFinishParent }
                        />
                </van-popup>
                
            </div>
        )
    }
})